<template>
    <div>
        <FormTemplate
            @submit="validate"
        >
            <ContactInformation ref="contactInformation"/>
            <Checkbox
                :error="isSubmit && !agreement"
                v-model="agreement"
            >
                <div v-html="checkCookie ? acceptanceText : acceptPdpp"></div>
            </Checkbox>
            <Checkbox
                v-if="autoPaymentText"
                v-model="autoPayment"
            >
                <div v-html="acceptSub"></div>
            </Checkbox>

            <Checkbox v-if="acceptanceAd" v-model="isAcceptAd">
                <div v-html="acceptanceAd"></div>
            </Checkbox>
        </FormTemplate>
    </div>
</template>

<script>
import anticharge from '@/api/anticharge'
import store from '@/store'

import Cookies from 'js-cookie'

import comebacker from '@/mixins/comebacker'
import sbg from '@/mixins/sbg'

import ContactInformation from '@/applications/loan-application/components/contact-information/ContactInformation'
import FormTemplate from '@/templates/form/Form'

import Storage from '@/ext/storage/Storage'

export default {
    name: 'Primary',
    mixins: [
        sbg,
        comebacker,
    ],
    data() {
        return {
            agreement: false,
            autoPayment: false,
            isSubmit: false,
            isAcceptAd: false,
            acceptPdpp: 'Совершая любые действия на сайте вы даете <a href="/api/get-doc/pdpp" target="_blank">Согласие на обработку персональных данных</a>.',
            acceptSub: `
              Вы ознакомились и даете
              <a href="/api/get-doc/subs" target="_blank">Согласие на подписку</a> и
              <a href="/api/get-doc/sign_agree" target="__blank">Согласие на использование простой электронной подписи </a>.
              Вы ознакомились и соглашаетесь с <a href="/api/get-doc/offer_insurance" target="_blank">Договором публичной оферты</a>,
              <a href="/api/get-doc/privacy" target="_blank">Политикой Конфиденциальности</a>,
              <a href="/api/get-doc/rules" target="_blank">Правилами страхования</a> и <a href="/api/get-doc/statement_insurance" target="_blank">
              Заявлением на включение в список застрахованных лиц</a>.
              <br>
              Соглашаетесь с автоматическими платежами и с включением в список застрахованных лиц. <b>Стоимость платной услуги 1497 рублей, оплата 3 платежами по 499 рублей раз в 5 дней.
        `,
        }
    },
    created() {
        this.autoPayment = this.agreement = this.isSbg

        if (this.isAnticharge) {
            alert(`Дата создания: ${this.$store.state.application.createdAt}`)
        }
    },
    mounted() {
        const phoneWatcher = this.$watch('$refs.contactInformation.form.phone', (phone) => {
            if (phone.length === 16 && this.agreement) {
                this.submit(false)
                phoneWatcher()
                agreementWatcher()
            }
        })

        const agreementWatcher = this.$watch('agreement', (agreement) => {
            if (this.$refs.contactInformation.form.phone.length === 16 && agreement) {
                this.submit(false)
                phoneWatcher()
                agreementWatcher()
            }
        })
    },
    computed: {
        isAnticharge() {
            return this.$route.name === 'Anticharge'
        },
        acceptanceText() {
            if (!Cookies.get('sbg-in'))
                return this.$DICTIONARY.acceptanceTextFirst || this.$DICTIONARY.acceptanceText

            return this.$DICTIONARY.acceptanceText
        },
        autoPaymentText() {
            return this.$DICTIONARY.acceptanceAutoPayment
        },
        acceptanceAd() {
            if (Cookies.get('sbg-cpa')) return
            return 'Соглашаюсь на получение <a href="/api/get-doc/ad">рекламных материалов</a>'
            // return 'Соглашение на рассылку'
        },
        checkCookie() {
          return (
            Cookies.get('sbg-cpa') !== undefined || Cookies.get('CPA') !== undefined
          );
        },
    },
    methods: {
        validate() {
            this.isSubmit = true
            this.$refs.contactInformation.validate();

            // ((this.autoPaymentText && this.autoPayment) || !this.autoPaymentText) &&
            this.$refs.contactInformation.isValid && this.agreement && this.submit()
        },
        async submit(send = true) {
            const { checkPhoneByCode, noValid } = await this.$store.dispatch('application/send', {
                contactData: this.$refs.contactInformation.form,
                showLoader: send,
                isAgreed4ad: this.isAcceptAd,
            })

            if (noValid && Object.keys(noValid)) {
                const formErrors = this.$refs.contactInformation.formErrors

                formErrors.phone = (noValid.phone === false && 'Невалидное значение') || ''
                formErrors.email = (noValid.email === false && 'Невалидное значение') || ''

                return;
            }

            if (!send)
                return;

            if (!this.autoPayment) {
              return this.$router.push({
                name: 'Final'
              })
            }

            if (!checkPhoneByCode) {
                this.$router.push({ name: 'LoanContact' })
            } else {
                Storage.set('user_phone', this.$refs.contactInformation.form.phone)
                this.$router.push({ name: 'LoanAuth' })
            }
        }
    },
    async beforeRouteEnter(to, from, next) {
        if (to.name === 'Anticharge') {
            await anticharge(to.query)
            await store.dispatch('application/update')
        }

        store.commit('application/load', false)
        next()
    },
    components: {
        ContactInformation,
        FormTemplate,
        Checkbox: () => import('@/components/form/checkbox/Checkbox')
    }
}
</script>